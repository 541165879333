/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lazysizes@5.3.2/plugins/progressive/ls.progressive.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/lazysizes@5.3.2/plugins/print/ls.print.min.js
 * - /npm/lazysizes@5.3.2/plugins/parent-fit/ls.parent-fit.min.js
 * - /npm/lazysizes@5.3.2/plugins/object-fit/ls.object-fit.min.js
 * - /npm/lazysizes@5.3.2/plugins/native-loading/ls.native-loading.min.js
 * - /npm/lazysizes@5.3.2/plugins/include/ls.include.min.js
 * - /npm/lazysizes@5.3.2/plugins/bgset/ls.bgset.min.js
 * - /npm/lazysizes@5.3.2/plugins/unveilhooks/ls.unveilhooks.min.js
 * - /npm/lazysizes@5.3.2/plugins/video-embed/ls.video-embed.min.js
 * - /npm/lazysizes@5.3.2/plugins/respimg/ls.respimg.min.js
 * - /npm/lazysizes@5.3.2/plugins/aspectratio/ls.aspectratio.min.js
 * - /npm/lazysizes@5.3.2/plugins/rias/ls.rias.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
